.wrapper {
  width: 100%;
  //display: flex;
  //align-items: center;
  //flex-direction: column;

  .container {
    //width: 100%;
    //padding: 50px 0 0;
    //max-width: 1200px;
    //
    //.flexWrapper {
    //  display: flex;
    //  align-items: center;
    //  justify-content: center;
    //}

    //Card {
    //  padding: 15px;
    //}
    //
    //.cardHeader {
    //  display: flex;
    //  padding: 15px 0 0 0;
    //  align-items: center;
    //  justify-content: center;
    //
    //  > span {
    //    font-size: 22px;
    //    font-weight: bold;
    //  }
    //}
  }
}
