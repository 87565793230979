.Upload {
  .container {
    displaY: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    height: 500px;
  }
  .comingSoon {
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: .2rem;
  }
}