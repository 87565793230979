@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700&display=swap');

.App {
  width: calc(100% - 300px); //300px for the width of the side menu
  height: 100%;
  display: flex;
  padding: 0 0 0 300px; //Padding for fixed side menu
  min-height: 100vh;
  font-family: 'Montserrat', sans-serif;
  justify-content: space-between;

  .sideMenuWrapper {
    top: 0;
    left: 0;
    width: 300px; // Change padding in .App too
    height: 100%;
    padding: 20px;
    position: fixed;
    min-height: calc(100vh - 40px);
    background-color: #fff;
  }

  .appContentWrapper {
    width: calc(100% - 40px);
    margin-left: 40px;
    display: flex;
    justify-content: center;
  }

  .appContent {
    width: 100%;
    padding: 0 15px;
  }
}