body, html {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*Overwrite default DropzoneArea styling (profile page)*/
#root > div > div.appContentWrapper > div > div > div.Profile_container__0Wt39 > div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root.css-1kzkjbv-MuiPaper-root-MuiCard-root > div > div {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

#root div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root.css-1kzkjbv-MuiPaper-root-MuiCard-root > div > div > div > svg {
  color: #959494 !important;
}

#root > div > div.appContentWrapper > div > div > div.Profile_container__0Wt39 > div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root.css-1kzkjbv-MuiPaper-root-MuiCard-root > div > div > div > p {
  margin: 0;
  font-size: 18px !important;
}
