.SideMenuItem {
  width: 100%;
  display: flex;
  padding: 8px 0;
  align-items: center;

  a {
    color: #000;
    padding: 0 0 0 20px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    text-decoration: none;
  }
}