.header {
  width: 100%;
  display: flex;
  padding: 20px 0;
  align-items: center;
  justify-content: center;

  img {
    height: 55px;
  }
}
