.sideMenu {
  width: 300px;
  padding: 50px 0 0;

  .profileInfo {
    display: flex;
    align-items: center;
    flex-direction: column;

    .circleShortAccountName {
      color: #fff;
      width: 85px;
      height: 85px;
      display: flex;
      position: relative;
      font-size: 30px;
      align-items: center;
      font-weight: 500;
      border-radius: 50%;
      text-transform: uppercase;
      justify-content: center;

      &:after {
        right: 11px;
        width: 12px;
        border: 1px solid #fff;
        bottom: 2px;
        height: 12px;
        content: "";
        position: absolute;
        border-radius: 50%;
        background-color: #21d94a;
      }
    }

    .profileCompanyName {
      padding: 20px 0 10px;
      font-size: 22px;
      text-align: center;
      font-weight: bold;
    }

    .profileCompanyAddress {
      color: #787878;
      font-size: 13px;
    }

    .spacer {
      width: 100%;
      height: 1px;
      margin: 50px 0 30px;
      background-color: #d3d3d3;
    }
  }

  .menu {

    ul {

      li {
        list-style-type: none;
      }
    }
  }
}